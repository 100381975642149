import { useQuery } from '@tanstack/vue-query';
import { getNotificationsCounter } from '../api';

const useGetNotificationsCounterQuery = () => {
  return useQuery({
    queryKey: ['notifications', 'counter'],
    async queryFn() {
      const response = await getNotificationsCounter();

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetNotificationsCounterQuery;
