import i18n from '@/plugins/i18n';
import { full } from '../constants';

const generateYearOptions = month => {
  let start = 2020;
  const end = new Date().getFullYear();
  const result = [];
  while (start <= end) {
    result.push({ title: start, value: start + '-' + month + '-' + '01' });
    start++;
  }
  return result;
};

export const getYearOptions = requestDate => {
  const month = requestDate?.split?.('-')?.[1] ?? '01';
  return [{ title: i18n.global.t('shared.ui.select.options.all'), value: null }].concat(generateYearOptions(month));
};

export const getMonthOptions = (requestDate, locale) => {
  const localeMonths = full[locale];
  return localeMonths.reduce?.(
    (result, item, index) => {
      const date =
        (requestDate?.split?.('-')?.[0] ?? new Date().getFullYear()) +
        '-' +
        index?.toString().padStart(2, '0') +
        '-' +
        '01';
      if (index)
        result.push({
          title: item,
          value: date,
        });
      return result;
    },
    [{ title: i18n.global.t('shared.ui.select.options.all'), value: null }]
  );
};
