export default {
  features: {
    warehouseItemsDownload: {
      select: {
        title: 'Скачать',
        options: ['Скачать товары всех магазинов (excel)', 'Скачать товары текущего магазина (excel)'],
      },
    },
  },
};
