// todo refactor module name
const moduleName = 'module_azo';

import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import { ROUTE_NAMES } from '../../common/constants/routeNames';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

export default [
  {
    path: '/nasiya/requests',
    name: ROUTE_NAMES.requestsIndex,
    component: () => import('../views/NasiyaRequestsIndexPage'),
    redirect: () => {
      return {
        name: ROUTE_NAMES.moderationRequests,
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Запросы'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_moderation_goods']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/requests/:requestId',
    name: ROUTE_NAMES.requestsShow,
    component: () => import('../views/NasiyaRequestsShowPage'),
    redirect: to => {
      return {
        name: ROUTE_NAMES.moderationRequestsShow,
        params: {
          moderationRequestId: to.params.requestId,
        },
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Запросы'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_moderation_goods']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/requests/add-items',
    name: ROUTE_NAMES.requestsAddItems,
    component: () => import('../views/NasiyaRequestsAddItemsPage'),
    redirect: () => {
      return {
        name: ROUTE_NAMES.warehouseItemsNew,
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Запросы'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_moderation_goods']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/warehouse-items/new',
    name: ROUTE_NAMES.warehouseItemsNew,
    component: async () => {
      const { CreateWarehouseItem } = await import('@/to-fsd/pages/create-warehouse-item');

      return CreateWarehouseItem;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Запросы'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_moderation_goods']),
        fetchNasiyaResources,
      ],
    },
  },
];
