export default {
  widgets: {
    offerDemands: {
      table: {
        empty: "Bo'sh",
        headers: ['Nomi', 'Yaratilgan sana', 'Holati', ''],
        showBy: "Ko'rsatish",
      },
    },
  },
};
