export default {
  widgets: {
    cashbacksList: {
      filters: {
        contractDate: {
          placeholder: 'Дата создания',
        },
        chargedAt: {
          placeholder: 'Дата начисления',
        },
        clear: 'Сброс',
      },
      table: {
        headers: ['СУММА ЗАЯВКИ', 'БОНУС', 'ДАТА ОФОРМЛЕНИЯ', 'ДАТА НАЧИСЛЕНИЯ', 'СТАТУС'],
      },
      generalInfo: {
        total: {
          title: 'Начислено за всё время',
          desc:
            'Общие начисления с момента подключения бонусной системы (данные обновляются 1-го числа и 16-го числа каждого месяца)',
        },
        charged: {
          title: 'Одобрено за все время',
          desc: 'Есть оформленная заявка, и есть активная сессия в alif mobi',
        },
        toPay: {
          title: 'Готово к начислению',
          desc: 'Накопленные бонусы с момента последнего начисления. При последующем начислении счетчик обнуляется',
        },
        notApproved: {
          title: 'Не одобрено',
          desc: 'Есть оформленная заявка, но нет активной сессии в alif mobi',
        },
        reject: {
          title: 'Списано за возврат',
          desc: 'Списание суммы бонуса за возврат рассрочки, по которой ранее были начисления в alif mobi',
        },
      },
    },
  },
};
