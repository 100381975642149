export default {
  features: {
    attachClientCard: {
      fields: {
        cardNumber: {
          label: 'Karta raqami',
        },
        expire: {
          label: 'Karta muddati',
        },
        phone: {
          label: 'Telefon raqami',
        },
        otp: {
          label: 'Tasdiqlash kodi',
        },
      },
      create: 'Tasdiqlash',
      cancel: 'Bekor qilish',
      requestCreate: 'Kartani qo‘shish',
      smsWasSentToMessage: 'SMS-kod mijoz raqamiga yuborildi',
    },
  },
};
