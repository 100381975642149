import { OFFER_DEMAND_STATUS } from '../constants';

export default {
  entities: {
    offerDemand: {
      statusText: {
        [OFFER_DEMAND_STATUS.new]: 'Yangi',
        [OFFER_DEMAND_STATUS.processing]: 'Ko`rib chiqilmoqda',
        [OFFER_DEMAND_STATUS.to_correct]: 'Tuzatishda',
        [OFFER_DEMAND_STATUS.to_moderation]: 'Moderatsiyada',
        [OFFER_DEMAND_STATUS.approved]: 'Tasdiqlandi',
        [OFFER_DEMAND_STATUS.rejected]: 'Rad etidi',
      },
    },
  },
};
