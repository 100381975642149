export default {
  shared: {
    ui: {
      select: {
        options: {
          all: 'Все',
        },
      },
      otpInfoBlock: {
        title: 'Почему смс не приходит?',
        steps: [
          'Проверить баланс телефона.',
          'Проверить память на телефоне (если памяти нет, то очистить).',
          'Попробовать выключить и снова включить телефон.',
          'Проверить, не добавлен ли Alif Nasiya в спам.',
          'Если ничего из вышеперечисленного не помогло, то обратиться в {technicalSupport}',
        ],
        technicalSupport: 'тех. поддержку.',
      },
    },
  },
};
