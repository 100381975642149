import AtToast from '@/plugins/aliftech-ui/toast';
import { setToken } from '@/services';
import { useMainStore } from '@/modules/main/common/stores';
import { useUserStore } from '@/modules/main/user/stores';
import { ROUTE_NAMES } from '@/modules/main/common/constants/routeNames';
import i18n from '@/plugins/i18n';
import { app as Vue } from '@/main';
import { resetStores } from '@/stores';

const requireAccessToAtLeastOneModule = async ({ to, next }) => {
  const mainStore = useMainStore();
  const userStore = useUserStore();

  const showModulesAccessDeniedToast = () => {
    AtToast.error({
      title: i18n.global.t('system.errors.modulesAccessDenied'),
      duration: 7000,
    });
  };

  const destroyLoginDetails = () => {
    Vue.config.globalProperties.$cookies.delete('auth');
    setToken('');
    resetStores();
  };

  const userPermissions = userStore.me.permissions;
  const userPermissionNames = userPermissions.map(permission => permission.name);

  const accessMap = {
    module_azo: mainStore.app.merchant.modules.includes('BNPL') && userPermissionNames.includes('merchant_alifnasiya'),
    module_acquiring:
      userPermissionNames.includes('merchant_acquiring') &&
      (mainStore.app.merchant.modules.includes('ACQUIRING') || mainStore.app.merchant.modules.includes('BML')),
    module_alifshop:
      mainStore.app.merchant.modules.includes('BNPL') && userPermissionNames.includes('merchant_alifshop'),
    module_admin:
      ((mainStore.app.merchant.modules.includes('BNPL') && userPermissionNames.includes('merchant_alifnasiya')) ||
        (mainStore.app.merchant.modules.includes('ACQUIRING') && userPermissionNames.includes('merchant_acquiring')) ||
        (mainStore.app.merchant.modules.includes('BNPL') && userPermissionNames.includes('merchant_alifshop'))) &&
      userPermissionNames.includes('merchant_admin'),
  };
  const hasAccessToAtLeastOneModule = Object.values(accessMap).some(Boolean);

  if (!hasAccessToAtLeastOneModule) {
    destroyLoginDetails();
    showModulesAccessDeniedToast();

    return { name: ROUTE_NAMES.login, query: { from: to.fullPath } };
  }

  return next();
};

export default requireAccessToAtLeastOneModule;
