import imageLoader from '@/directives/imageLoader';
import mask from '@/directives/mask';
import money from '@/directives/money';
import clickOutside from '@/plugins/clickOutside';
import viewer from '@/directives/viewer';
import api from '@/directives/viewer/api';
// import i18n from '@/plugins/i18n';

export const vueAppErrorHandlers = [];

export const addVueAppErrorHandler = handler => {
  vueAppErrorHandlers.push(handler);
};

export const removeVueAppErrorHandler = handler => {
  const index = vueAppErrorHandlers.indexOf(handler);

  if (index !== -1) {
    vueAppErrorHandlers.splice(index, 1);
  }
};

export default {
  install(app) {
    // Подключение базовых компонентов
    const requireComponent = require.context('../components/base', true, /Base[^\\.]+.(js|vue)/);
    requireComponent.keys().forEach(fileName => {
      const { default: component } = requireComponent(fileName);
      let componentName = (fileName[0].toUpperCase() + fileName.substring(1)).replace(/^\.\/(.*)\.\w+$/, '$1');
      const splitName = componentName.split('/');
      if (splitName.length) {
        const name = splitName.find(name => /At/g.test(name));
        if (name !== undefined && typeof name === 'string') {
          componentName = name;
        }
      }
      if (componentName === component?.name) app.component(componentName, component);
    });

    //Directives
    app.directive('image-loader', imageLoader);
    app.directive('mask', mask);
    app.directive('money', money);
    app.directive('click-outside', clickOutside);
    //Viewer
    app.directive('viewer', viewer);
    app.config.globalProperties[`$viewerApi`] = api;
    //Viewer - END
    app.config.errorHandler = message => {
      // eslint-disable-next-line
      console.error(message);
      vueAppErrorHandlers.forEach(handler => {
        handler(message);
      });
    };
  },
};
