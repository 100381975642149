export default {
  entities: {
    clientPassport: {
      clientExistsModal: {
        title: 'Клиент с такими данными уже существует',
        subTitle:
          'Для оформлении рассрочки требуется регистрация клиента через приложение Alif Business на мобильном телефоне',
        steps: ['Скачать приложение отсканировав QR-код', 'Введите данные клиента', 'Регистрируйте клиента через MyID'],
        footer: 'Не создавайте клиентов с поддельными паспортными данными!',
        ok: 'Понятно',
      },
    },
  },
};
