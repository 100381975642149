export default {
  widgets: {
    analyticsDemography: {
      title: 'Demografiya',
      select: {
        labels: {
          source: 'Manba',
          month: 'Oy',
          year: 'Yil',
          age: 'Yosh',
        },
        options: {
          loading: 'Yuklanmoqda...',
          all: 'Barchasi',
          months: [
            '',
            'Yanvar',
            'Fevral',
            'Mart',
            'Aprel',
            'May',
            'Iyun',
            'Iyul',
            'Avgust',
            'Sentabr',
            'Oktabr',
            'Noyabr',
            'Dekabr',
          ],
          ages: ['18-25', '26-32', '33-40', '41-55', '56 va katta'],
        },
        titles: {
          choose: 'Qiymatni tanlang',
        },
      },
      chart: {
        labels: {
          female: 'Ayollar',
          male: 'Erkaklar',
        },
      },
      demography: 'Demografiya',
      tabs: {
        monthly: 'Oyiga',
        yearly: 'Yiliga',
      },
    },
  },
};
