export default {
  widgets: {
    cashbacksList: {
      filters: {
        contractDate: {
          placeholder: 'Yaratilgan sana',
        },
        chargedAt: {
          placeholder: 'Hisobga olingan sana',
        },
        clear: 'Tozalash',
      },
      table: {
        headers: [
          'RASMIYLASHTIRILGAN ARIZA SUMMASI',
          'BONUS',
          'RASMIYLASHTIRILGAN SANA',
          "BONUS O'TKAZILGAN SANA",
          'HOLAT',
        ],
      },
      generalInfo: {
        total: {
          title: "Umumiy o'tkazilgan bonus miqdori",
          desc:
            "Bonus tizimiga ulangan paytdan boshlab umumiy o'tkazilgan bonus miqdori (ma'lumotlar har oyning 1 va 16-kunlarida yangilanadi)",
        },
        charged: {
          title: 'Barcha tasdiqlanishlar',
          desc: 'Rasmiylashtirilgan ariza va alif mobida faol sessiya mavjud',
        },
        toPay: {
          title: 'Hisoblash uchun tayyor',
          desc:
            'Oxirgi hisoblash paytidan buyon yig‘ilgan bonuslar. Keyingi hisoblashdan keyin hisoblagich nolga qaytariladi',
        },
        notApproved: {
          title: 'Tasdiqlanmagan',
          desc: "Rasmiylashtirilgan ariza bor, ammo alif mobida faol sessiya yo'q",
        },
        reject: {
          title: 'Aytarish uchun hisobdan chiqarildi',
          desc: "Rasmiylashtirilgan ariza qaytarilganda alif mobida ilgari o'tkazilgan bonus miqdori qaytarib olinishi",
        },
      },
    },
  },
};
