export default {
  widgets: {
    alifshopOffers: {
      filters: {
        search: {
          placeholder: 'Qidirish...',
        },
        category: {
          placeholder: 'Barcha toifalar',
        },
        brand: {
          placeholder: 'Brend',
        },
        hasProduct: {
          placeholder: 'Omborda mavjud',
          options: ['Mavjud', 'Mavjud emas'],
        },
        priceOrder: {
          placeholder: 'Narxi',
          options: ['Birinchi qimmat', 'Birinchi arzon'],
        },
      },
      reset: 'tozalash',
      table: {
        headers: ['Nomi', 'Yaratilgan sana', 'Sayta', ''],
        showBy: "Ko'rsatish",
        empty: "Bo'sh",
      },
    },
  },
};
