export default {
  widgets: {
    warehouseItems: {
      filters: {
        search: {
          placeholder: 'Izlash...',
        },

        isVisible: {
          placeholder: "Alifshopda ko'rinish",
          options: ['Saytda', 'Saytda emas'],
        },

        hasOffer: {
          placeholder: "Shablonga bog'lash",
          options: ['Saytga tayyor', 'Saytga tayyor emas'],
        },

        quantity: {
          placeholder: 'Tovarlar mavjudligi',
          options: [
            'Mavjud',
            'Mavjud emas',
            '5 donagacha',
            '10 donagacha',
            '15 donagacha',
            '20 donagacha',
            '50 donagacha',
            '50 donagacha ortiq',
          ],
        },

        reset: 'Tozalash',
      },
      showBy: "Ko'rsatish",
      headers: {
        items: 'Tovarlar',
        status: 'Holati',
        ikpu: 'MXIK',
        sku: 'SKU',
        price: "Narxi (so'm)",
        price_brutto: 'QQS bilan narx',
        quantity: 'Omborda',
        inAlifshop: 'Alifshopda',
      },

      selectedItemsActions: {
        title: 'Tanlangan tovarlar',
        list: ["O'chirish", "Sotuvga qo'yish", 'Tovarlarni yashirish'],
      },
    },
  },
};
