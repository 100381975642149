export default {
  widgets: {
    analyticsApplications: {
      title: 'Arizalar soni',
      select: {
        labels: {
          source: 'Manba',
          store: "Do'kon",
          consultant: 'Konsultant',
          applicationStatus: 'Ariza holati',
          month: 'Oy',
          year: 'Yil',
        },
        options: {
          loading: 'Yuklanmoqda...',
          all: 'Barchasi',
          months: [
            '',
            'Yanvar',
            'Fevral',
            'Mart',
            'Aprel',
            'May',
            'Iyun',
            'Iyul',
            'Avgust',
            'Sentabr',
            'Oktabr',
            'Noyabr',
            'Dekabr',
          ],
        },
        titles: {
          choose: 'Qiymatni tanlang',
          consultant: 'Konsultant',
          store: "Do'kon nomini kiriting",
        },
      },
      applicationsSum: 'Arizalar qiymati',
      applicationsCount: 'Arizalar soni',
      errors: {
        noData: "Ushbu so'rov bo'yicha ma'lumot topilmadi",
      },
      radio: {
        labels: {
          sum: 'Qiymati',
          count: 'Soni',
        },
      },
      tabs: {
        applications: 'Arizalar',
        monthly: 'Oyiga',
        yearly: 'Yiliga',
      },
    },
  },
};
