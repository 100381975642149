// todo refactor module name
const moduleName = 'module_azo';

import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import { ROUTE_NAMES } from '../../common/constants/routeNames';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

export default [
  {
    path: '/nasiya/warehouse',
    name: ROUTE_NAMES.warehouseList,
    component: () => import('../views/NasiyaWarehouseIndexPage'),
    redirect: () => {
      return {
        name: ROUTE_NAMES.warehouseFsd,
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Склад'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/warehouse/to-fsd',
    name: ROUTE_NAMES.warehouseFsd,
    component: async () => {
      const { WarehousePage } = await import('@/to-fsd/pages/warehouse');

      return WarehousePage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Склад'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/warehouse/:id',
    name: ROUTE_NAMES.warehouseShow,
    component: () => import('../views/NasiyaWarehouseShowPage'),
    redirect: to => {
      return {
        name: ROUTE_NAMES.warehouseShowToFsd,
        params: {
          warehouseItemId: to.params.id,
        },
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Склад'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/warehouse/:warehouseItemId/to-fsd',
    name: ROUTE_NAMES.warehouseShowToFsd,
    component: async () => {
      const { WarehouseItemsShowPage } = await import('@/to-fsd/pages/warehouse-items-show');

      return WarehouseItemsShowPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Склад'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/warehouse/offer-demands/create/:id',
    name: ROUTE_NAMES.offerDemandsCreate,
    component: () => import('../views/offer-demands/WarehouseOfferDemandsCreatePage.vue'),
    meta: {
      middlewares: [
        setDocumentTitle('Создание заявки'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/warehouse/offer-demands/show/:id',
    name: ROUTE_NAMES.offerDemandsShowPage,
    component: () => import('../views/offer-demands/NasiyaWarehouseOfferDemandsShowPage.vue'),
    meta: {
      middlewares: [
        setDocumentTitle('Склад'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/warehouse/offer-demands/edit/:id',
    name: ROUTE_NAMES.offerDemandsEditPage,
    component: () => import('../views/offer-demands/NasiyaWarehouseOfferDemandEditPage.vue'),
    meta: {
      middlewares: [
        setDocumentTitle('Склад'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/warehouse/offers/show/:id',
    name: ROUTE_NAMES.offerShowPage,
    component: () => import('../views/NasiyaWarehouseOfferShowPage.vue'),
    meta: {
      middlewares: [
        setDocumentTitle('Склад'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_upload_goods']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
];
