import { webServiceHash } from '../../constants';
import { useI18n } from 'vue-i18n';

const useWebServiceOptions = () => {
  const { t } = useI18n();
  return [
    { value: null, title: t('system.titles.all') },
    ...Object.keys(webServiceHash).map(key => ({
      value: key,
      title: webServiceHash[key],
    })),
  ];
};

export default useWebServiceOptions;
