import { OFFER_DEMAND_STATUS } from '../constants';

export default {
  entities: {
    offerDemand: {
      statusText: {
        [OFFER_DEMAND_STATUS.new]: 'Новый',
        [OFFER_DEMAND_STATUS.processing]: 'Рассматривается',
        [OFFER_DEMAND_STATUS.to_correct]: 'На исправлении',
        [OFFER_DEMAND_STATUS.to_moderation]: 'На модерации',
        [OFFER_DEMAND_STATUS.approved]: 'Одобрено',
        [OFFER_DEMAND_STATUS.rejected]: 'Отказано',
      },
    },
  },
};
