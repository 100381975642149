export default {
  features: {
    createWarehouseItem: {
      submit: 'Отправить заявку',
      addMore: 'Добавить еще',
      formTitle: 'Товар',
      createWithExcelButton: 'Загрузить excel',
    },
  },
};
