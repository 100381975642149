export default {
  entities: {
    clientPassport: {
      clientExistsModal: {
        title: 'Shunday maʼlumotlarga ega mijoz mavjud',
        subTitle:
          'Boʻlib-boʻlib toʻlash uchun roʻyxatdan oʻtish mijozning mobil telefonidagi Alif Business ilovasi orqali amalga oshiriladi',
        steps: [
          'QR-kodni skanerlash orqali ilovani yuklab oling',
          'Mijoz maʼlumotlarini kiriting',
          'Mijozni MyID orqali roʻyxatdan oʻtkazing',
        ],
        footer: 'Soxta pasport maʼlumotlariga ega mijozlarni yaratmang!',
        ok: 'Tushunarli',
      },
    },
  },
};
