export default {
  features: {
    createWarehouseItem: {
      submit: 'Arizani yuborish',
      addMore: "Yana qo'shish",
      formTitle: 'Mahsulot',
      createWithExcelButton: 'Excel yuklash',
    },
  },
};
