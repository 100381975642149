export default {
  widgets: {
    analyticsConsultants: {
      title: 'Arizalar soni',
      select: {
        labels: {
          source: 'Manba',
          consultant: 'Konsultant',
          month: 'Oy',
          year: 'Yil',
        },
        options: {
          loading: 'Yuklanmoqda...',
          all: 'Barchasi',
          months: [
            '',
            'Yanvar',
            'Fevral',
            'Mart',
            'Aprel',
            'May',
            'Iyun',
            'Iyul',
            'Avgust',
            'Sentabr',
            'Oktabr',
            'Noyabr',
            'Dekabr',
          ],
        },
        titles: {
          choose: 'Qiymatni tanlang',
          consultant: 'Konsultant',
        },
      },
      chart: {
        labels: {
          problematicCredits: "Muammoli muddatli to'lovlar",
          approvedApplications: 'Rasmiylashtirilgan arizalar',
        },
      },
      tabs: {
        monthly: 'Oyiga',
        yearly: 'Yiliga',
      },
    },
  },
};
