export default {
  widgets: {
    analyticsDemography: {
      title: 'Демография',
      select: {
        labels: {
          source: 'Источник',
          month: 'Месяц',
          year: 'Год',
          age: 'Возраст',
        },
        options: {
          loading: 'Загружается...',
          all: 'Все',
          months: [
            '',
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ],
          ages: ['18-25', '26-32', '33-40', '41-55', '56 и больше'],
        },
        titles: {
          choose: 'Выберите значение',
        },
      },
      chart: {
        labels: {
          female: 'Женщины',
          male: 'Мужчины',
        },
      },
      demography: 'Демография',
      tabs: {
        monthly: 'За месяц',
        yearly: 'За год',
      },
    },
  },
};
