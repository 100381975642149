import { useQuery } from '@tanstack/vue-query';
import { getNotifications } from '../api';

const useGetNotificationsQuery = params => {
  return useQuery({
    queryKey: ['notifications', 'list', params],
    async queryFn({ queryKey }) {
      const params = queryKey[2];

      const response = await getNotifications(params);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetNotificationsQuery;
