// todo refactor module name
const moduleName = 'module_azo';

import { ROUTE_NAMES } from '../../common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

export default [
  {
    path: '/nasiya/excels',
    name: ROUTE_NAMES.excelsList,
    component: () => import('../views/NasiyaExcelsIndexPage'),
    redirect: () => {
      return {
        name: ROUTE_NAMES.reportsList,
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Отчёты'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/reports',
    name: ROUTE_NAMES.reportsList,
    component: async () => {
      const { ReportsPage } = await import('@/to-fsd/pages/reports');

      return ReportsPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Отчёты'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
        fetchNasiyaResources,
      ],
    },
  },
];
