export default {
  widgets: {
    analyticsPopularItems: {
      title: 'Популярные товары',
      select: {
        labels: {
          source: 'Источник',
          month: 'Месяц',
          year: 'Год',
          limit: 'Отображать в таблице по:',
        },
        options: {
          loading: 'Загружается...',
          all: 'Все',
          months: [
            '',
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ],
        },
        titles: {
          choose: 'Выберите значение',
        },
      },
      table: {
        headers: {
          titles: {
            items: 'Товар',
            sales: 'Продажи',
            salesSum: 'Продаж на сумму',
            price: 'Цена',
          },
        },
      },
      tabs: {
        monthly: 'За месяц',
        yearly: 'За год',
      },
    },
  },
};
