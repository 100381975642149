export default {
  pages: {
    bonuses: {
      title: 'Bonuslar',
      introductionModal: {
        title: 'Taklifnoma',
        body: [
          'Hurmatli hamkorlar va konsultantlar',
          'Biz siz uchun yangi bonuslarimizni ishga tushirmoqdamiz!',
          "Endi siz rasmiylashtirgan arizalaringiz uchun alif mobi ilovasida qo'shimcha bonuslaringizni olishingiz mumkin.",
        ],
        confirm: 'Tushunarli',
      },
      dropdown: {
        title: 'Bu qanday ishlaydi?',
        menu: {
          title: 'Talablar roʻyxati:',
        },
      },
    },
  },
};
