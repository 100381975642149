export default {
  widgets: {
    alifshopOffers: {
      filters: {
        search: {
          placeholder: 'Поиск...',
        },
        category: {
          placeholder: 'Все категории',
        },
        brand: {
          placeholder: 'Бренд',
        },
        hasProduct: {
          placeholder: 'На складе',
          options: ['Имеется', 'Не имеется'],
        },
        priceOrder: {
          placeholder: 'Цена',
          options: ['Сначала дорогие', 'Сначала дешёвые'],
        },
      },
      reset: 'Сбросить',
      table: {
        headers: ['НАЗВАНИЕ', 'ДАТА СОЗДАНИЯ', 'На сайте', ''],
        showBy: 'Показывать по',
        empty: 'Пусто',
      },
    },
  },
};
