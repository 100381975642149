export default {
  widgets: {
    analyticsApplicationsMean: {
      title: "O'rtacha chek",
      select: {
        labels: {
          source: 'Manba',
          store: "Do'kon",
          consultant: 'Konsultant',
          applicationStatus: 'Ariza holati',
          month: 'Oy',
          year: 'Yil',
        },
        options: {
          loading: 'Yuklanmoqda...',
          all: 'Barchasi',
          months: [
            '',
            'Yanvar',
            'Fevral',
            'Mart',
            'Aprel',
            'May',
            'Iyun',
            'Iyul',
            'Avgust',
            'Sentabr',
            'Oktabr',
            'Noyabr',
            'Dekabr',
          ],
        },
        titles: {
          choose: 'Qiymatni tanlang',
          consultant: 'Konsultant',
          store: "Do'kon nomini kiriting",
        },
      },
      applicationsMean: "O'rtacha chek",
      tabs: {
        monthly: 'Oyiga',
        yearly: 'Yiliga',
      },
    },
  },
};
