export default {
  widgets: {
    offerDemands: {
      table: {
        empty: 'Пусто',
        headers: ['НАЗВАНИЕ', 'ДАТА СОЗДАНИЯ', 'Статус', ''],
        showBy: 'Показывать по',
      },
    },
  },
};
