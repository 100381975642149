export default {
  features: {
    attachClientCard: {
      fields: {
        cardNumber: {
          label: 'Номер карты',
        },
        expire: {
          label: 'Срок карты',
        },
        phone: {
          label: 'Номер телефона',
        },
        otp: {
          label: 'Код подтверждение',
        },
      },
      create: 'Подтвердить',
      cancel: 'Отмена',
      requestCreate: 'Добавить карту',
      smsWasSentToMessage: 'СМС-код был отправлен на номер клиента',
    },
  },
};
