export default {
  entities: {
    warehouseItem: {
      form: {
        name: {
          label: 'Название',
        },
        ikpu: {
          label: 'ИКПУ',
        },
        price: {
          label: 'Цена',
        },
        sku: {
          label: 'SKU',
        },
        quantity: {
          label: 'Количество',
        },
        thirdCategory: {
          label: 'Категория',
        },
        gtin: {
          label: 'Штрих код',
          search: 'Найти',
        },
        searchByGtin: 'Поиск по штрих коду',
      },
    },
  },
};
