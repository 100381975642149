export default {
  features: {
    warehouseItemsDownload: {
      select: {
        title: 'Yuklab olish',
        options: ["Barcha do'konlar tovarlarini yuklab olish (excel)", "Joriy do'kon tovarlarini yuklab olish (excel)"],
      },
    },
  },
};
