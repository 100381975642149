export default {
  widgets: {
    analyticsApplicationsMean: {
      title: 'Средний чек',
      select: {
        labels: {
          source: 'Источник',
          month: 'Месяц',
          year: 'Год',
        },
        options: {
          loading: 'Загружается...',
          all: 'Все',
          months: [
            '',
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ],
        },
        titles: {
          choose: 'Выберите значение',
          consultant: 'Консультант',
          store: 'Введите название магазина',
        },
      },
      applicationsMean: 'Средний чек',
      tabs: {
        monthly: 'За месяц',
        yearly: 'За год',
      },
    },
  },
};
