export default {
  pages: {
    analytics: {
      title: 'Analitika',
      tabs: {
        sales: 'Sotuvlar',
        applications: 'Arizalar',
        applicationsMean: "O'rtacha chek",
        popularCategories: 'Ommabop toifalar',
        popularItems: 'Ommabop mahsulotlar',
        demography: 'Demografiya',
        consultants: 'Konsultant',
        monthly: 'Oyiga',
        yearly: 'Yiliga',
      },
    },
  },
};
