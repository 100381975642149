export default {
  pages: {
    bonuses: {
      title: 'Бонусы',
      introductionModal: {
        title: 'Приглашение',
        body: [
          'Уважаемые партнеры и консультанты',
          'Рады сообщить вам о запуске наших новых бонусов!',
          'Теперь вы можете предоставлять рассрочки клиентам и получать за это дополнительные бонусы в нашем приложении alif mobi.',
        ],
        confirm: 'Понятно',
      },
      dropdown: {
        title: 'Как это работает?',
        menu: {
          title: 'Список требований:',
        },
      },
    },
  },
};
