export default {
  pages: {
    analytics: {
      title: 'Аналитика',
      tabs: {
        sales: 'Продажи',
        applications: 'Заявки',
        applicationsMean: 'Средний чек',
        popularCategories: 'Популярные категории',
        popularItems: 'Популярные товары',
        demography: 'Демография',
        consultants: 'Консультанты',
        monthly: 'За месяц',
        yearly: 'За год',
      },
    },
  },
};
